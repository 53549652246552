import { Link } from 'react-router-dom'
import React from 'react'
import line from '../../assets/images/anim_line.png'
import blueapp from '../../assets/images/appstore_blue.png'
import blue from '../../assets/images/googleplay_blue.png'
import logo from '../../assets/images/bb4k-crop.png'
import top from '../../assets/images/go_top.png'
import Goone from "../../assets/images/go_top-one.png"
import Section from '../../assets/images/section-bg.png'
import Shape1 from '../../assets/images/banner-shape-one1.png'
import Shape2 from "../../assets/images/banner-shape2two.png"
import Shape3 from "../../assets/images/banner-shapethree3.png"
import Footerimg from "../../assets/images/footer_logo-one.png"
import Banner1 from '../../assets/images/banner-shape1.png'
import Banner2 from '../../assets/images/banner-shape2.png'

const Main = ({ footer }) => {

    return (
        <>
            {/* <section className="newsletter_section">
                <div className="container">
                    <div className="newsletter_box">
                        <div className="section_title" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                            <h2>Subscribe newsletter</h2>
                            <p>Be the first to recieve all latest post in your inbox</p>
                        </div>
                        <form action="" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                            <div className="form-group">
                                <input type="email" className="form-control" placeholder="Enter your email" />
                            </div>
                            <div className="form-group">
                                <button className="btn">SUBMIT</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section> */}

            <footer>
                <div className="top_footer top_footer-dark" id="contact">
                    <div className="container">
                        <span className="banner_shape1"> <img src={Shape1} alt="image" /> </span>
                        <span className="banner_shape2"> <img src={Shape2} alt="image" /> </span>
                        <span className="banner_shape3"> <img src={Shape3} alt="image" /> </span>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="abt_side">
                                    <div className="logo"> <img src={logo} alt="image" /></div>
                                    {/* <ul>
                                        <li><Link to="#">contact@bb4k.co</Link></li>
                                        <li><Link to="#">+40 751 394 860</Link></li>
                                    </ul> */}
                                    {/* <ul className="social_media">
                                        <li><Link to="#"><i className="icofont-facebook"></i></Link></li>
                                        <li><Link to="#"><i className="icofont-twitter"></i></Link></li>
                                        <li><Link to="#"><i className="icofont-instagram"></i></Link></li>
                                        <li><Link to="#"><i className="icofont-pinterest"></i></Link></li>
                                    </ul> */}
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="links">
                                    <h3>Contact us</h3>
                                    <ul>
                                        <li><Link to="#">contact@bb4k.co</Link></li>
                                        <li><Link to="#">+40 751 394 860</Link></li>
                                        {/* <li><Link to="#">Services</Link></li>
                                        <li><Link to="/blog">Blog</Link></li>
                                        <li><Link to="/contact">Contact us</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="links">
                                    <h3>Address</h3>
                                    <ul>
                                        <li><Link to="#">Romania, Bucharest, Splaiul Independentei 313B, Bl. C5, Ap. 5</Link></li>
                                        {/* <li><Link to="#">Support</Link></li>
                                        <li><Link to="/work">How it works</Link></li>
                                        <li><Link to="#">Terms & conditions</Link></li>
                                        <li><Link to="#">Privacy policy</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-12">
                                <div className="try_out">
                                    {/* <h3>Let’s Try Out</h3>
                                    <ul className="app_btn">
                                        <li>
                                            <Link to="#">
                                                <img src={blueapp} alt="image" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <img src={blue} alt="image" />
                                            </Link>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom_footer bottom_footer-dark">
                    <div className="container">

                        <div className="row">
                            <div className="col-md-6">
                                <p>© Copyrights 2024. All rights reserved.</p>
                            </div>
                            <div className="col-md-6">
                                <p className="developer_text">Design & developed by <Link to="#" target="blank">Bb4k Software Labs</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="go_top">
                    <span><img src={top} alt="image" /></span>
                </div>
            </footer>
        </>
    )
}

export default Main