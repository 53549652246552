import React from 'react';
import { Link } from 'react-router-dom';

const expertsData = [
    {
        name: 'Steav Joe',
        position: 'CEO & Co-Founder',
        image: 'assets/images/experts_01.png',
    },
    {
        name: 'Mark Dele',
        position: 'Co-Founder',
        image: 'assets/images/experts_02.png',
    },
    {
        name: 'Jolley Sihe',
        position: 'Business Developer',
        image: 'assets/images/experts_03.png',
    },
    {
        name: 'Rimy Nail',
        position: 'Marketing & Sales',
        image: 'assets/images/experts_04.png',
    },
];

function Meet() {
    return (
        <>
            <section className="row_am experts_team_section">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                        <h2>
                            {' '}
                            Meet our <span> experts </span>
                        </h2>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            <br /> indus orem Ipsum has beenthe standard dummy.
                        </p>
                    </div>
                    <div className="row">
                        {expertsData.map((expert, index) => (
                            <div key={index} className="col-md-6 col-lg-3"
                                data-aos="fade-up"
                                data-aos-duration="1500"
                                data-aos-delay={`${100 * (index + 1)}`}>
                                <div className="experts_box">
                                    <img src={expert.image} alt="image" />
                                    <div className="text">
                                        <h3>{expert.name}</h3>
                                        <span>{expert.position}</span>
                                        <ul className="social_media">
                                            <li>
                                                <Link to="#">
                                                    <i className="icofont-facebook"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="icofont-twitter"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="icofont-instagram"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Meet;
