import { HashLink as Link } from 'react-router-hash-link';
import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import anim from '../../assets/images/anim_line.png'
import blueapp from '../../assets/images/appstore_blue.png'
import whiteapp from '../../assets/images/appstore_white.png'
import blue from '../../assets/images/googleplay_blue.png'
import white from '../../assets/images/googleplay_white.png'
import used1 from '../../assets/images/used01.png'
import used2 from '../../assets/images/used02.png'
import used3 from '../../assets/images/used03.png'
import used4 from '../../assets/images/used04.png'
import msg from '../../assets/images/message_icon.png'
import shield from '../../assets/images/shield_icon.png'
import screen from '../../assets/images/screen.png'
import frame from '../../assets/images/mobile_frame_svg.svg'

function Sliderone() {

    const frmae_slider = {
        loop: true,
        margin: 10,
        nav: false,
        autoplay: true,
        smartSpeed: 1500,
        dots: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    }

    return (
        <>
            <section className="banner_section home-banner">
                <div className="container">
                    <div className="anim_line">
                        <span><img src={anim} alt="anim_line" /></span>
                        <span><img src={anim} alt="anim_line" /></span>
                        <span><img src={anim} alt="anim_line" /></span>
                        <span><img src={anim} alt="anim_line" /></span>
                        <span><img src={anim} alt="anim_line" /></span>
                        <span><img src={anim} alt="anim_line" /></span>
                        <span><img src={anim} alt="anim_line" /></span>
                        <span><img src={anim} alt="anim_line" /></span>
                        <span><img src={anim} alt="anim_line" /></span>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12" data-aos="fade-right" data-aos-duration="1500">
                            <div className="banner_text">
                                <h1>Best way to <span>manage your customers.</span></h1>
                                <p>Lorem Ipsum is simply dummy text of the printing and setting indus orem Ipsum has been the industrys.
                                </p>
                            </div>
                            <ul className="app_btn">
                                <li>
                                    <Link to="#">
                                        <img className="blue_img" src={blueapp} alt="image" />
                                        <img className="white_img" src={whiteapp} alt="image" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        <img className="blue_img" src={blue} alt="image" />
                                        <img className="white_img" src={white} alt="image" />
                                    </Link>
                                </li>
                            </ul>
                            <div className="used_app">
                                <ul>
                                    <li><img src={used1} alt="image" /></li>
                                    <li><img src={used2} alt="image" /></li>
                                    <li><img src={used3} alt="image" /></li>
                                    <li><img src={used4} alt="image" /></li>
                                </ul>
                                <p>12M + <br /> used this app</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12" data-aos="fade-in" data-aos-duration="1500">
                            <div className="banner_slider">
                                <div className="left_icon">
                                    <img src={msg} alt="image" />
                                </div>
                                <div className="right_icon">
                                    <img src={shield} alt="image" />
                                </div>
                                <OwlCarousel id="frmae_slider" className="owl-carousel owl-theme owl-loaded owl-drag" {...frmae_slider}>
                                    <div className="item">
                                        <div className="slider_img">
                                            <img src={screen} alt="image" />
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="slider_img">
                                            <img src={screen} alt="image" />
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="slider_img">
                                            <img src={screen} alt="image" />
                                        </div>
                                    </div>
                                </OwlCarousel>
                                <div className="slider_frame">
                                    <img src={frame} alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sliderone