import React from 'react'
import Darkimg from "../../assets/images/download-dark.png"
import Followerimg from "../../assets/images/followers-dark.png"
import Reviewdark from "../../assets/images/reviews.png"
import Countriesdark from "../../assets/images/countries-dark.png"
import CountUp from 'react-countup';

function Counter() {
    return (
        <>
            <section className="row_am statistic_section">
                <div className="container">
                    <ul className="app_statstic" id="counter" >
                        <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12" data-aos="fade-up" data-aos-duration="1000">
                            <li>
                                <div className="icon">
                                    <img src={Darkimg} alt="image" />
                                </div>
                                <div className="text">
                                    <p><CountUp  className="counter-value" data-count="17" start={0} end={17} duration={2.5}></CountUp><span></span></p>
                                    <p>Projects</p>
                                </div>
                            </li>
                        </div>

                        {/* <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12 " data-aos="fade-up" data-aos-duration="1200">
                            <li>
                                <div className="icon">
                                    <img src={Followerimg} alt="image" />
                                </div>
                                <div className="text">
                                    <p>$<CountUp  className="counter-value" data-count="08" start={0} end={600} duration={2.5}></CountUp><span>K+</span></p>
                                    <p>Generated Profit</p>
                                </div>
                            </li>
                        </div>

                        <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12 " data-aos="fade-up" data-aos-duration="1400">
                            <li>
                                <div className="icon">
                                    <img src={Followerimg} alt="image" />
                                </div>
                                <div className="text">
                                    <p><CountUp  className="counter-value" data-count="2300" start={0} end={200} duration={2.5}></CountUp><span>k+</span></p>
                                    <p>Active Users</p>
                                </div>
                            </li>
                        </div> */}

                        <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12 " data-aos="fade-up" data-aos-duration="1600">
                            <li>
                                <div className="icon">
                                    <img src={Countriesdark} alt="image" />
                                </div>
                                <div className="text">
                                    <p><CountUp  className="counter-value" data-count="150" start={0} end={9} duration={2.5}></CountUp><span></span></p>
                                    <p>Developers</p>
                                </div>
                            </li>
                        </div>
                    </ul>
                </div>
            </section>
        </>
    )
}

export default Counter