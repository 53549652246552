import React from 'react'

function Different() {

    const boxes = [
        {
            icon: 'assets/images/secure.png',
            title: 'Secure code',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting indus ideas.'
        },
        {
            icon: 'assets/images/abt_functional.png',
            title: 'Fully functional',
            description: 'Simply dummy text of the printing and typesetting indus lorem Ipsum is dummy.'
        },
        {
            icon: 'assets/images/communication.png',
            title: 'Best communication',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting indus ideas.'
        },
        {
            icon: 'assets/images/abt_support.png',
            title: '24-7 Support',
            description: 'Simply dummy text of the printing and typesetting indus lorem Ipsum is dummy.'
        }
    ];

    return (
        <>
            <section className="row_am why_we_section" data-aos="fade-in">
                <div className="why_inner">
                    <div className="container">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            <h2><span>Why we are different</span> from others!</h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typese tting <br /> indus orem Ipsum has beenthe standard
                                dummy.</p>
                        </div>
                        <div className="row">
                            {boxes.map(box => (
                                <div className="col-md-6 col-lg-3" key={box.title}>
                                    <div className="why_box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                        <div className="icon">
                                            <img src={box.icon} alt="image" />
                                        </div>
                                        <div className="text">
                                            <h3>{box.title}</h3>
                                            <p>{box.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Different
