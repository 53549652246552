import React from 'react'
import img1 from '../../../assets/images/modern01.png'
import img2 from '../../../assets/images/standard.png'
import img3 from '../../../assets/images/modern02.png'
import Sectionbg from "../../../assets/images/section-bg.png"

const Main = ({ gredient }) => {

    const designBlocks = [
        {
            title: 'Custom Software Development',
            content: 'Bespoke solutions crafted to meet your exact needs.'
        },
        {
            title: 'Web & Mobile App Development',
            content: 'Engaging, user-friendly apps that leave a lasting impression.'
        },
        {
            title: 'Dedicated Teams',
            content: 'Plug into our team of experts and supercharge your capabilities.'
        },
        {
            title: 'Tech Strategy & Consulting',
            content: 'Get the insights and strategic direction to stay ahead.'
        }
    ];

    return (
        <>
            <section className={`row_am modern_ui_section ${gredient && "gredient-bg"}`} >
                {gredient &&
                    <div className="modernui_section_bg modernui-gredient"> <img src={Sectionbg} alt="image" /> </div>}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="ui_text">
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                    <h2>Our Services</h2>
                                    <p>
                                        Imagine a software partner that truly gets you—one that dives into the heart of your business and works like an extension of your team.
                                    </p>
                                </div>
                                <ul className="design_block">
                                    {designBlocks.map((block, index) => (
                                        <li key={index} data-aos="fade-up" data-aos-duration="1500">
                                            <h4>{block.title}</h4>
                                            <p>{block.content}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="ui_images" data-aos="fade-in" data-aos-duration="1500">
                                <div className="left_img">
                                    <img className="moving_position_animatin" src={img1} alt="image" />
                                </div>
                                <div className="right_img">
                                    {[img2, img3].map((img, index) => (
                                        <img key={index} className="moving_position_animatin" src={img} alt="image" />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Main
