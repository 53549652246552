import React from 'react'
import { Link } from 'react-router-dom'
import Bredcrumb from '../Bredcrumb/Main'
import BGImg from "../../assets/images/bread_crumb_bg.png"
import BGImg1 from "../../assets/images/bread_crumb_bg_one.png"
import BGImg2 from "../../assets/images/bread_crumb_bg_two.png"
import Leave from '../Mencontact/Leave'
import Map from '../Mencontact/Map'

const Main = ({ brdcum }) => {
    return (

        <>
            {brdcum.b1 &&
                <Bredcrumb no={1} title="Contact Us" paragraph="Let's get in touch." tag="Contact us" bgimg={BGImg} />
            }

            {brdcum.b2 &&
                <Bredcrumb no={2} title="Contact Us" paragraph="Let's get in touch." tag="Contact us" bgimg={BGImg1} />
            }

            {brdcum.b5 &&
                <Bredcrumb no={5} title="Contact Us" paragraph="Let's get in touch." tag="Contact us" bgimg={BGImg} />
            }

            {brdcum.b3 &&
                <Bredcrumb no={3} title="Contact Us" paragraph="Let's get in touch." tag="Contact us" />
            }

            {brdcum.b4 &&
                <Bredcrumb no={4} title="Contact Us" paragraph="Let's get in touch." tag="Contact us" bgimg={BGImg2} />
            }

            <Leave />
            {/* <Map /> */}

        </>
    )
}

export default Main