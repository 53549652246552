import React from 'react';

function Features() {
    const features = [
        {
            id: 1,
            imageSrc: 'assets/images/features1.png',
            title: 'Secure data',
            description: 'Lorem Ipsum is simply dummy text of the printing and typese tting indus orem Ipsum has beenthe standard dummy.',
            duration: 1500,
        },
        {
            id: 2,
            imageSrc: 'assets/images/features2.png',
            title: 'Automate everything',
            description: 'Lorem Ipsum is simply dummy text of the printing and typese tting indus orem Ipsum has beenthe standard dummy.',
            duration: 1700,
        },
        {
            id: 3,
            imageSrc: 'assets/images/features3.png',
            title: 'Secure data',
            description: 'Lorem Ipsum is simply dummy text of the printing and typese tting indus orem Ipsum has beenthe standard dummy.',
            duration: 1900,
        },
    ];

    return (
        <section className="row_am features_section homedark-features" id="features">
            <div className="container">
                <div className="features_inner">
                    <div
                        className="section_title"
                        data-aos="fade-up"
                        data-aos-duration="1500"
                        data-aos-delay="300"
                    >
                        <h2>
                            Welcome to <span>Bb4k</span> Software Labs
                        </h2>
                        <p>
                            A next-gen software development company powered by a small but mighty team of creative developers.
                            <br /> Whether you’re a startup, a growing business, or an established enterprise, we’re here to turn your tech dreams into reality.
                        </p>
                    </div>
                    <div className="features_block">
                        <div className="row">
                            {features.map((feature) => (
                                <div className="col-md-4" key={feature.id}>
                                    <div
                                        className="feature_box"
                                        data-aos="fade-up"
                                        data-aos-duration={feature.duration}
                                    >
                                        <div className="image">
                                            <img src={feature.imageSrc} alt="image" />
                                        </div>
                                        <div className="text">
                                            <h4>{feature.title}</h4>
                                            <p>{feature.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Features;
