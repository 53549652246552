import React, { useEffect } from 'react'
import AOS from "aos";
import Design from '../HomeMain/Design/Main'
import Work from '../HomeMain/Work/Main'
import Testimonial from '../HomeMain/Testimonial/Main'
import AboutApp from '../HomeMain/AboutApp/Main'
import Trusted from '../HomeMain/Trusted/Main'
import Pricing from '../HomeMain/Pricing/Main'
import Faq from '../HomeMain/Faq/Main'
import Interface from '../HomeMain/Interface/Main'
import Story from '../HomeMain/Story/Main'
import Banner from '../Menhomewave/Banner'
import Counter from '../Menhomedark/Counter'
import Features from '../Menhomedark/Features'
import Download from '../HomeMain/Download/Main'

const Main = ({ setnavbar, setfooter, setbrdcum }) => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

    useEffect(() => {
        setfooter({ f2: true })
        setnavbar({ n3: true })
        setbrdcum({ b2: true })
        localStorage.setItem("navbar", "darkhome");
    }, [])

    return (
        <>
            <Banner />
            <Counter />
            {/* <Features /> */}
            {/* <AboutApp dark={true} />*/}
            
            <Work dark={true} />
            <Design /> 
            {/* <Testimonial />
            <Trusted /> */}
            <Pricing />
            {/* <Faq /> */}
            {/* <Interface /> */}
            {/* <Download /> */}
            <Story />
        </>
    )
}

export default Main