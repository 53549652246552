import React from 'react'
import CountUp from 'react-countup';
import Abtimg from "../../assets/images/about_main.png"
import Downloadimg from "../../assets/images/download.png"
import Followerimg from "../../assets/images/followers.png"
import Reviewimg from "../../assets/images/reviews.png"
import Countries from "../../assets/images/countries.png"

function Focus() {
    return (
        <>
            <section className="row_am about_app_section about_page_sectino">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="abt_img" data-aos="fade-in" data-aos-duration="1500">
                                <img src={Abtimg} alt="image" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about_text">
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                    <h2> <span>We focus on quality,</span> never
                                        focus on quantity</h2>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has been the industrys standard dummy text ever since the when an unknown printer took a galley of type and.
                                    </p>
                                </div>
                                <ul className="app_statstic" id="counter" data-aos="fade-in" data-aos-duration="1500">
                                    <li>
                                        <div className="icon">
                                            <img src={Downloadimg} alt="image" />
                                        </div>
                                        <div className="text">
                                            <p><CountUp className="counter-value" data-count="17" start={0} end={17} duration={2.75}></CountUp><span>M+</span></p>
                                            <p>Download</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <img src={Followerimg} alt="image" />
                                        </div>
                                        <div className="text">
                                            <p><CountUp className="counter-value" data-count="08" start={0} end={8} duration={2.75}></CountUp><span>M+</span></p>
                                            <p>Followers</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <img src={Reviewimg} alt="image" />
                                        </div>
                                        <div className="text">
                                            <p><CountUp className="counter-value" data-count="2300" start={0} end={2300} duration={2.75}></CountUp><span>+</span></p>
                                            <p>Reviews</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <img src={Countries} alt="image" />
                                        </div>
                                        <div className="text">
                                            <p><CountUp className="counter-value" data-count="150" start={0} end={150} duration={2.75}></CountUp><span>+</span></p>
                                            <p>Countries</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Focus