import { Link } from 'react-router-dom'
import React from 'react'
import story1 from '../../../assets/images/story01.png'
import story2 from '../../../assets/images/story02.png'
import story3 from '../../../assets/images/story03.png'

const stories = [
    {
        imgSrc: story1,
        timeAgo: 'Co-Founder, CTO',
        title: 'Robert Nicolescu',
        text: 'From leading complex projects to mentoring our team of developers, he ensures every line of code is not just functional but exceptional.',
        link: 'https://www.linkedin.com/in/robert-nicolescu/',
    },
    {
        imgSrc: story2,
        timeAgo: 'Co-Founder, CTO',
        title: 'Dragoș Bălmău',
        text: 'With a deep-rooted passion for coding and an eye for detail, he is the technical powerhouse of Bb4k. A problem-solver that loves turning challenges into innovative solutions.',
        link: 'https://www.linkedin.com/in/dragosbalmau/',
    },
];

const Main = () => {
    return (
        <>
            <section className="row_am latest_story" id="blog">
                <div className="container">
                    <div className="section_title" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                        <h2>Meet the <span>founders</span></h2>
                        <p>Great products start with great people, <br />and at <b>Bb4k</b>, our founders set out to make a difference not just in software, but in how teams work. <br />Frustrated by rigid, uninspired work environments, they built <b>Bb4k</b> with a clear mission:<br /> to create a space where creativity thrives, collaboration flows, and every team member feels valued.</p>
                    </div>
                    <div className="row">
                        {stories.map((story, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="story_box" data-aos="fade-up" data-aos-duration="1500">
                                    <div className="story_img">
                                        <img src={story.imgSrc} alt="image" />
                                        <span>{story.timeAgo}</span>
                                    </div>
                                    <div className="story_text">
                                        <h3>{story.title}</h3>
                                        <p>{story.text}</p>
                                        <a target='_blank' href={story.link}>See Profile</a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Main;
