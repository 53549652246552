import React, { useState } from 'react'
import Abtimg1 from "../../assets/images/abt_01.png"
import Abtimg2 from "../../assets/images/abt_02.png"
import Abtimg3 from "../../assets/images/abt_03.png"
import Playback from "../../assets/images/play_black.png"

function Providing() {

    const [ytShow , setytShow] = useState (false);

    return (
        <>
            <section className="row_am app_solution_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="app_text">
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                    <h2><span>Providing innovative app solution</span> to make customer
                                        life easy to grow.</h2>
                                </div>
                                <p data-aos="fade-up" data-aos-duration="1500">
                                    Lorem Ipsum is simply dummy text of the printing and type
                                    setting industry lorem Ipsum has been the industrys standard dummy text ever since the when an unknown
                                    printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
                                    five centuries, but also the leap into electronic typesetting, remaining to make a type speci
                                    men book. It has survived essentially unchanged.
                                </p>
                                <p data-aos="fade-up" data-aos-duration="1500">
                                    Standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to
                                    make a type specien book. It has survived not only five centuries, but also the leap into electronic
                                    typesetting.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="app_images" data-aos="fade-in" data-aos-duration="1500">
                                <ul>
                                    <li><img src={Abtimg1} alt="" /></li>
                                    <li>
                                        <a data-url="#" onClick={() => setytShow(true)} className="popup-youtube play-button"
                                            data-toggle="modal"
                                            data-target="#myModal" title="About Video">
                                            <img src={Abtimg2} alt="" />
                                            <div className="waves-block">
                                                <div className="waves wave-1"></div>
                                                <div className="waves wave-2"></div>
                                                <div className="waves wave-3"></div>
                                            </div>
                                            <span className="play_icon"><img src={Playback} alt="image" /></span>
                                        </a>
                                    </li>
                                    <li><img src={Abtimg3} alt="" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {ytShow &&
                <>
                    <div className="modal fade youtube-video show" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-modal="true" style={{paddingRight: "17px", display: "block"}}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                            <button id="close-video" type="button" className="button btn btn-default text-right" data-dismiss="modal">
                                <i className="icofont-close-line-circled" onClick={() => setytShow(false)}></i>
                            </button>
                                <div className="modal-body">
                                    <div id="video-container" className="video-container">
                                        <iframe id="youtubevideo" src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&amp;mute=1" width="640" height="360" frameborder="0" allowfullscreen=""></iframe>
                                    </div>        
                                </div>
                                <div className="modal-footer">
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="purple_backdrop"></div>
                </>
            }
        </>
    )
}

export default Providing