import React from 'react'
import Trusted from '../HomeMain/Trusted/Main'
import Bredcrumb from '../Bredcrumb/Main'
import BGImg from "../../assets/images/bread_crumb_bg.png"
import BGImg1 from "../../assets/images/bread_crumb_bg_one.png"
import BGImg2 from "../../assets/images/bread_crumb_bg_two.png"
import Providing from '../Menabout/Providing'
import Different from '../Menabout/Different'
import Focus from '../Menabout/Focus'
import Meet from '../Menabout/Meet'
import Have from '../Menabout/Have'

const Main = ({ brdcum }) => {

    return (
        <>
            {brdcum.b1 &&
                <Bredcrumb no={1} title="About Us" tag="About us" bgimg={BGImg} />
            }

            {brdcum.b2 &&
                <Bredcrumb no={2} title="About Us" tag="About us" bgimg={BGImg1} />
            }

            {brdcum.b5 &&
                <Bredcrumb no={5} title="About Us" tag="About us" bgimg={BGImg} />
            }


            {brdcum.b3 &&
                <Bredcrumb no={3} title="About Us" tag="About us" />
            }

            {brdcum.b4 &&
                <Bredcrumb no={4} title="About Us" tag="About us" bgimg={BGImg2} />
            }

            <Providing />
            <Different />
            <Focus />
            <Meet />
            <Have />
            <Trusted />
        </>

    )
}

export default Main