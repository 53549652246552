import React from 'react'
import { Link } from 'react-router-dom'
import Blueimg from "../../assets/images/appstore_blue.png"
import Whiteimg from "../../assets/images/appstore_white.png"
import Googleplayimg from "../../assets/images/googleplay_blue.png"
import Googleplaywhite from "../../assets/images/googleplay_white.png"
import Used1 from "../../assets/images/used01.png"
import Used2 from "../../assets/images/used02.png"
import Used3 from "../../assets/images/used03.png"
import Used4 from "../../assets/images/used04.png"
import Bannerimg from "../../assets/images/banner-image.png"
import Shape1 from "../../assets/images/banner-shape1.png"
import Shape2 from "../../assets/images/banner-shape2.png"
import Shape3 from "../../assets/images/banner-shape3.png"



function Banner() {
    return (
        <>
            <section className="banner_section home-wave">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12" data-aos="fade-right" data-aos-duration="1500">
                            <div className="banner_text">
                                <h1>The best<br /> Dream to Reality<br /> converter</h1>
                                {/* <p>
                                    Lorem Ipsum is simply dummy text of the printing and setting indus orem Ipsum has been the industrys.
                                </p> */}
                            </div>
                            {/* <ul className="app_btn">
                                <li>
                                    <Link to="#">
                                        <img className="blue_img" src={Blueimg} alt="image" />
                                        <img className="white_img" src={Whiteimg} alt="image" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        <img className="blue_img" src={Googleplayimg} alt="image" />
                                        <img className="white_img" src={Googleplaywhite} alt="image" />
                                    </Link>
                                </li>
                            </ul>
                            <div className="used_app">
                                <ul>
                                    <li><img src={Used1} alt="image" /></li>
                                    <li><img src={Used2} alt="image" /></li>
                                    <li><img src={Used3} alt="image" /></li>
                                    <li><img src={Used4} alt="image" /></li>
                                </ul>
                                <p>12M + <br /> used this app</p>
                            </div> */}
                        </div>
                        <div className="col-lg-6 col-md-12" data-aos="fade-in" data-aos-duration="1500">
                            <div className="banner_image">
                                <img className="moving_animation" src={Bannerimg} alt="image" />
                            </div>
                        </div>

                        {/* <span className="banner_shape1"> <img src={Shape1} alt="image" /> </span>
                        <span className="banner_shape2"> <img src={Shape2} alt="image" /> </span>
                        <span className="banner_shape3"> <img src={Shape3} alt="image" /> </span> */}

                    </div>

                </div>
                <div className="home-wave-bottom">
                    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                        <defs>
                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g className="parallax">
                            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#f6f4fe" />
                        </g>
                    </svg>
                </div>
            </section>
        </>
    )
}

export default Banner