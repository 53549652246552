import React from 'react'
import { Link } from 'react-router-dom'
import Shape1 from "../../assets/images/banner-shape1.png"
import Shape2 from "../../assets/images/banner-shape2.png"
import Shape3 from "../../assets/images/banner-shape3.png"


function Have() {
    return (
        <>
            <section className="row_am query_section">
                <div className="query_inner" data-aos="fade-in" data-aos-duration="1500">
                    <div className="container">
                        <span className="banner_shape1"> <img src={Shape1} alt="image" /> </span>
                        <span className="banner_shape2"> <img src={Shape2} alt="image" /> </span>
                        <span className="banner_shape3"> <img src={Shape3} alt="image" /> </span>
                        <div className="section_title">
                            <h2>Have any query about ?</h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typese tting <br /> indus orem Ipsum has beenthe standard dummy.</p>
                        </div>
                        <Link to="/contact" className="btn white_btn">CONTACT US NOW</Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Have